export const frozen = ["energy", "power"];

export const powerSymbols = {
  "-6": "10\u207B\u2076",
  "-5": "10\u207B\u2075",
  "-4": "10\u207B\u2074",
  "-3": "10\u207B\u00B3",
  "-2": "10\u207B\u00B2",
  "-1": "10\u207B\u00B9",
  0: "10\u2070",
  1: "10\u00B9",
  2: "10\u00B2",
  3: "10\u00B3",
  4: "10\u2074",
  5: "10\u2075",
};

export const xticksList = [
  1, 5, 10, 20, 30, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600, 1800, 2000, 3000, 4000,
  5000, 6000, 7000, 8000, 9000, 10000, 12000, 14000, 16000, 18000, 20000, 1000000000, 100000000000000000000000,
];

export const yticksList = [
  0.0000001, 0.0000002, 0.0000003, 0.0000004, 0.0000005, 0.000001, 0.000002, 0.000003, 0.000004, 0.000005, 0.00001,
  0.00002, 0.00003, 0.00004, 0.00005, 0.0001, 0.0002, 0.0003, 0.0004, 0.0005, 0.001, 0.002, 0.003, 0.004, 0.005, 0.01,
  0.02, 0.03, 0.04, 0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000,
  2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000, 1e5, 2e5, 3e5, 4e5, 5e5, 1e6, 2e6, 3e6, 4e6, 5e6, 7e6,
];

//by alias
export const colors = {
  //Violetine
  "DUV (merged)": "#ea00ed",
  "DUV": "#ea00ed",
  "DUV-SIG": "#ea00ed",
  "DUV-IDL": "#994499",
  "THS": "#8718c7",
  "UV extension": "#8718c7",
  
  //Melyna
  "VIS": "#8718c7",
  "FHS": "#8718c7",
  "FHI": "#0c00ff",

  "SHS": "#3366cc",
  "SHS (long)": "#093d71",
  "SHS (short)": "#6baaf4",

  "SHI": "#00c0ff",
  "SHI (long)": "#0086b1",
  "SHI (short)": "#72fffd",

  "VIS1": "#3366cc",
  "VIS2": "#3366cc",
  "VIS-NIR extension": "#3366cc",
  "Wide tuning range, UV": "#3366cc",
  "Aux Pump": "#3366cc",

  //Zalsvos
  "Signal": "#048302",
  "Signal (compressed)": "#069a04",
  "Signal (uncompressed)": "#069a04",
  "Signal (long)": "#04b14f",
  "Signal (short)": "#2ad257",
  "Standard": "#048302",
  "SIG": "#069a04",
  "SIG LONG": "#04b14f",
  "SIG LONG 1st": "#2aff00",
  "Signal+Idler": "#209b74",
  "SIG+IDL LONG": "#abf000",
  "SIG 1st": "#2ad257",

  //Rausvos
  "Idler": "#f3d400",
  "Idler (noncollinear)": "#f3d400",
  "Idler (uncompressed)": "#f3d400",
  "Idler (short)": "#f3d400",

  "Idler (collinear)": "#e3f300",
  "Idler (compressed)": "#e3f300",
  "CMP-IDL": "#e3f300",

  "Idler (long)": "#f39900",
  "Idler (narrowband)": "#f39900",
  "IDL LONG": "#f39900",
  "Idler Noncollinear": "#f3d400",
  "Idler Collinear": "#e3f300",
  "Narrowband extension": "#f39900",
  

  "Wide tuning range, NIR": "#f3d400",
  
  "DFG": "#f39900",
  "DFG1": "#f39900",
  "DFG2": "#f39900",
  "DFP1-IDL": "#f39900",
  "DFP2-IDL": "#9f0101",

  "hrD":"#9f0101",
  "sh": "#069a04",
  "th": "#3366cc",
  "fh": "#8718c7",
};

export function exponential_form(x) {
  var x2 = x;
  var i = 0;

  while (x2 >= 10) {
    x2 = x2 / 10.0;
    i += 1;
  }

  while (x2 < 1.0) {
    x2 = x2 * 10;
    i -= 1;
  }

  return x2.toFixed(1) + "\u00B7" + powerSymbols[i.toString()];
}

export function test_inside (x, m) {
  var xmin = m[0] < m[1] ? m[0] : m[1];
  var xmax = m[0] > m[1] ? m[0] : m[1]

  return x >= xmin && x < xmax;
}


export function  array_difference (arr1, arr2) {
  var i;
  var arr3 = [];

  if (arr1.length !== arr2.length) {
    return;
  }

  for (i = 0; i < arr1.length; i += 1) {
    if (arr1[i] === null) {
      arr3[i] = null;
    } else {
      arr3[i] = arr1[i] - arr2[i];
    }
  }

  return arr3;
}
