<template lang="">
  <!-- TITLE -->
  <div class="col-xs-12">
    <h3>Tuning curves</h3>
  </div>
  <div class="row">
    <!-- PLOT -->
    <div class="col-lg-8 col-xs-12">
      <div id="tuningcurves_plot" />
      <div
        v-if="hasRepRateError || hasEnergyError || hasPowerError"
        id="outofboundsnotification"
      >
        <div
          style="
            position: absolute;
            color: #b44;
            font-size: 24px;
            letter-spacing: 0.21em;
            margin-top: -530px;
            margin-left: 100px;
          "
        >
          Parameters out of bounds
        </div>
      </div>
    </div>

    <!-- INPUT -->
    <div
      v-if="productSpecs"
      class="col-lg-4 col-xs-12"
    >
      <!-- PRODUCT SELECT -->
      <Selector
        :list="productSpecs"
        :list-name="'ProductSpecs'"
        @selectedMaterial="onSelection"
      />

      <div v-if="selectedProduct">
        <TuningCurveSlider
          ref="energy"
          name="Pulse Energy"
          :range="selectedProduct.pumpEnergyRange"
          :init-value="10"
          :value="pulseEnergy"
          @onValueChange="onPulseEnergyChange"
          @freeze="pulseEnergyFreezed = !pulseEnergyFreezed"
          @error="hasEnergyError = !hasEnergyError"
        />
        <TuningCurveSlider
          ref="power"
          name="Power"
          :range="selectedProduct.pumpPowerRange"
          :init-value="5"
          :value="power"
          @onValueChange="onPowerChange"
          @freeze="powerFreezed = !powerFreezed"
          @error="hasPowerError = !hasPowerError"
        />
        <TuningCurveSlider
          ref="repetitionRate"
          name="Repetition rate"
          :range="selectedProduct.pumpRepRateRange"
          :init-value="500"
          :value="repetitionRate"
          @onValueChange="onRepetitionRateChange"
          @freeze="repRateFreezed = !repRateFreezed"
          @error="hasRepRateError = !hasRepRateError"
        />
      </div>
      <div class="row">
        <div
          v-if="selectedProduct"
          class="col-xs-7"
        >
          <h5>Interaction types:</h5>
          <label
            v-for="interaction in selectedProduct.interactions"
            :key="interaction"
          >
            <input
              v-model="selectedinteractions"
              type="checkbox"
              :disabled="calculateRange(interaction)"
              :value="interaction.alias"
              @change="plot('tuningcurves_plot')"
            >
            {{ interaction.alias }}
            <small>{{ interaction.pumpEnergyRange.min }}-{{ interaction.pumpEnergyRange.max }} μJ</small>
          </label>
        </div>
        <div class="col-xs-5">
          <h5>
            <input
              v-model="showBestEffort"
              type="checkbox"
            >
            Show best effort
          </h5>
          <div
            v-if="hasRepRateError || hasEnergyError || hasPowerError"
            class="tooltip2"
          >
            <span class="tooltiptext2">Energy out of bounds</span>
          </div>
          <button
            type="button"
            class="btn btn-default"
            style="width: 100%"
            :disabled="hasRepRateError || hasEnergyError || hasPowerError"
            @click="savePng"
          >
            Export PNG
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div
            id="special_notes"
            style="display: none"
            class="panel panel-primary panel-heading"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div
      v-for="(image, index) in images"
      :key="image"
      class="col-xs-6 col-lg-2"
    >
      <div class="panel panel-primary">
        <div class="panel-heading">
          {{ image.name
          }}<button
            type="button"
            class="close"
            data-dismiss="alert"
            @click="deleteImageFromArray(index)"
          >
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
        </div>
        <div class="panel-body">
          <img
            class="panel-image"
            :src="image.image"
            @click="downloadPng(image.image, image.name)"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TuningCurveSlider from "@/components/TuningCurveSlider.vue";
import Selector from "@/components/Selector.vue";
import {
  xticksList,
  yticksList,
  test_inside,
  colors,
  exponential_form,
  array_difference,
} from "@/tools/TuningCurves.js";
import $ from "jquery";
import { drawTuningCurvesD3 } from "@/tools/Plot.js";

export default {
  components: {
    TuningCurveSlider,
    Selector,
  },
  data() {
    return {
      selectedProduct: null,
      power: 5,
      pulseEnergy: 10,
      repetitionRate: 500,
      hasEnergyError: false,
      hasRepRateError: false,
      hasPowerError: false,
      powerFreezed: false,
      pulseEnergyFreezed: false,
      repRateFreezed: false,
      selectedinteractions: ["Signal", "Signal (long)", "Signal+Idler", "Idler", "Idler (long)", "SHS", "SHS (long)", "SHI", "SHI (long)", "THS"],
      showBestEffort: true,
      images: [],
    };
  },
  computed: {
    productSpecs() {
      return this.$store.state.productSpecs;
    },
    title() {
      return (
        this.selectedProduct.friendlyModelName +
        " " +
        Math.round10(this.pulseEnergy) +
        "\u03BCJ, " +
        Math.round10(this.power) +
        "W@" +
        Math.round10(this.repetitionRate) +
        "kHz"
      );
    },
  },

  watch: {
    showBestEffort() {
      this.plot("tuningcurves_plot");
    },
  },
  methods: {
    savePng() {
      const d3ToPng = require("d3-svg-to-png");
      var svg = document.getElementById("tuningcurves_plot").firstChild;
      d3ToPng(svg, this.title, {
        scale: 3,
        format: "png",
        quality: 0.01,
        download: false,
        ignore: ".ignored",
        background: "white",
      }).then((fileData) => {
        let image = { name: this.title, image: fileData };
        this.images.push(image);
        console.log("🚀 ~ file: TuningCurvesView.vue:186 ~ test ~ this.images:", this.images);
      });
    },
    downloadPng(image, title) {
      var a = document.createElement("a");
      a.href = image;
      a.download = title;
      a.click();
    },
    deleteImageFromArray(index) {
      this.images.splice(index, 1);
    },
    onSelection(specs) {
      console.log(specs);
      this.selectedProduct = specs;
      this.plot("tuningcurves_plot");
    },
    onPulseEnergyChange(pulseEnergy) {
      if (this.pulseEnergy !== pulseEnergy) {
        if (this.powerFreezed) {
          var repetitionRate = (this.power / pulseEnergy) * 1.0e3;
          if (repetitionRate !== this.repetitionRate && repetitionRate > 0) {
            this.repetitionRate = repetitionRate.toFixed(2);
          } else if (repetitionRate === 0) this.repetitionRate = this.selectedProduct.pumpRepRateRange.min;
        } else {
          var power = pulseEnergy * this.repetitionRate * 1.0e-3;
          if (power !== this.power && power > 0) {
            this.power = power.toFixed(2);
          } else if (power === 0) this.power = this.selectedProduct.pumpPowerRange.min;
        }
        this.$refs.energy.inputValue = pulseEnergy;
        this.pulseEnergy = pulseEnergy;
        this.plot("tuningcurves_plot");
      }
    },
    onPowerChange(power) {
      if (this.power !== power) {
        if (this.pulseEnergyFreezed) {
          var repetitionRate = (power / this.pulseEnergy) * 1.0e3;
          if (repetitionRate !== this.repetitionRate && repetitionRate > 0) {
            this.repetitionRate = repetitionRate.toFixed(2);
          } else if (repetitionRate === 0) this.repetitionRate = this.selectedProduct.pumpRepRateRange.min;
        } else {
          var pulseEnergy = (power / this.repetitionRate) * 1.0e3;
          if (pulseEnergy !== this.pulseEnergy && pulseEnergy > 0) {
            this.pulseEnergy = pulseEnergy.toFixed(2);
          } else if (pulseEnergy === 0) this.pulseEnergy = this.selectedProduct.pumpEnergyRange.min;
        }
        this.$refs.power.inputValue = power;
        this.power = power;
        this.plot("tuningcurves_plot");
      }
    },
    onRepetitionRateChange(repetitionRate) {
      if (this.repetitionRate !== repetitionRate) {
        if (this.powerFreezed) {
          var pulseEnergy = (this.power / repetitionRate) * 1.0e3;
          if (pulseEnergy !== this.pulseEnergy && pulseEnergy > 0) {
            this.pulseEnergy = pulseEnergy.toFixed(2);
          } else if (pulseEnergy === 0) this.pulseEnergy = this.selectedProduct.pumpEnergyRange.min;
        } else {
          var power = this.pulseEnergy * repetitionRate * 1.0e-3;
          if (power !== this.power && power > 0) {
            this.power = power.toFixed(2);
          } else if (power === 0) this.power = this.selectedProduct.pumpPowerRange.min;
        }
        this.$refs.repetitionRate.inputValue = repetitionRate;
        this.repetitionRate = repetitionRate;
        this.plot("tuningcurves_plot");
      }
    },
    calculateRange(interaction) {
      if (this.pulseEnergy >= interaction.pumpEnergyRange.min && this.pulseEnergy < interaction.pumpEnergyRange.max)
        return false;
      return true;
    },
    plot(caller) {
      var target_div;
      if (typeof caller === "undefined") {
        target_div = "tuningcurves_plot";
      } else {
        if (caller === "export") {
          target_div = "tuningcurves_export_plot";
        } else {
          target_div = "tuningcurves_plot";
        }
      }

      if (this.power === 0.0) return;

      var productData;
      var interactionsList = [];
      var interactionName;
      var isChecked = "";
      var i;
      var j;
      var global_i = 0;
      var global_max = 0;
      var powerScale;
      var fval;

      var x = [];
      var y = [];
      var yvalTotal = [];
      var yvalBE;
      var yvalSpecs;

      var xrange = [];
      var yrange = [1.0e20, -1.0e20];
      var yrangeProduct;

      var xticks = [];
      var yticks = [];
      var yticksEnergy = [];
      var xseries = [];

      productData = this.selectedProduct;

      for (i = 0; i < productData.interactions.length; i++) {
        if (this.selectedinteractions.indexOf(productData.interactions[i].alias) !== -1) {
          global_max += productData.interactions[i].points.length;
        }
      }

      for (i = 0; i < global_max; i += 1) {
        yvalTotal.push(0.0);
      }

      powerScale = this.power;

      $("#interactions_list").html("");
      for (i = 0; i < productData.interactions.length; i++) {
        interactionName = productData.interactions[i].alias;
        var interactionCode = productData.interactions[i].alias;
        interactionsList.push(interactionName);

        var inList = this.selectedinteractions.indexOf(interactionName) !== -1;
        console.log('this.selectedinteractions: ', this.selectedinteractions, "interactionName: ", interactionName);
        var inRange = test_inside(this.pulseEnergy, [
          productData.interactions[i].pumpEnergyRange.min,
          productData.interactions[i].pumpEnergyRange.max,
        ]);

        // ???? var inTuning = productData.interactions[i].IncludeInTuning;

        var isVisible = true;
        isChecked = inList;
        var isDisabled = !inRange;

        var isCheckedString = isChecked ? " checked" : " ";
        var isDisabledString = isDisabled ? " disabled" : " "; //(!inList && !inRange) ? " disabled" : "";

        var rangeString =
          " " +
          productData.interactions[i].pumpEnergyRange.min.toFixed(0) +
          "-" +
          productData.interactions[i].pumpEnergyRange.max.toFixed(0) +
          " \u03BCJ";

        if (isVisible) {
          $("#interactions_list").append(
            $(
              '<input type="checkbox" name="interaction_type" value="' +
                productData.interactions[i].alias +
                '"' +
                isCheckedString +
                isDisabledString + 
                "> " +
                productData.interactions[i].alias +
                "<small>" +
                rangeString +
                "</small></br>"
            )
          );
        }

        if (isChecked && isVisible && !isDisabled) {
          yvalSpecs = { title: interactionName, val: [], tooltip: [] };
          yvalBE = { title: interactionName + " (best effort)", val: [], tooltip: [] };
          for (j = 0; j < global_max; j += 1) {
            yvalSpecs.val.push(null);
            yvalSpecs.tooltip.push(null);
            yvalBE.val.push(null);
            yvalBE.tooltip.push(null);
          }

          yrangeProduct = [1.0e20, -1.0e20];

          for (j = 0; j < productData.interactions[i].points.length; j++) {
            x[global_i] = parseFloat(productData.interactions[i].points[j].wavelength);

            yvalBE.val[global_i] = parseFloat(productData.interactions[i].points[j].maxPower) * powerScale;
            yvalBE.tooltip[global_i] =
              x[global_i].toFixed(0) +
              " nm, " +
              interactionName +
              " (best effort): " +
              yvalBE.val[global_i].toFixed(0) +
              " mW, " +
              (yvalBE.val[global_i] / this.repetitionRate).toFixed(3) +
              " \u03BCJ" ;

            if (
              test_inside(productData.interactions[i].points[j].wavelength, [
                productData.interactions[i].specifiedWavelengthRange.min,
                productData.interactions[i].specifiedWavelengthRange.max,
              ])
            ) {
              yvalSpecs.val[global_i] = parseFloat(productData.interactions[i].points[j].minPower) * powerScale;
              yvalSpecs.tooltip[global_i] =
                x[global_i].toFixed(0) +
                " nm, " +
                interactionName +
                " (typical): " +
                yvalSpecs.val[global_i].toFixed(0) +
                " mW, " +
                (yvalSpecs.val[global_i] / this.repetitionRate).toFixed(3) +
                " \u03BCJ";
console.log('this.selectedProduct: ', this.selectedProduct);
              if (yvalSpecs.val[global_i] > yrange[1]) {
                yrange[1] = yvalSpecs.val[global_i];
              }
              if (yvalSpecs.val[global_i] < yrange[0]) {
                yrange[0] = yvalSpecs.val[global_i];
              }
            }

            if (yvalBE.val[global_i] > yrange[1]) {
              yrange[1] = yvalBE.val[global_i];
            }
            if (yvalBE.val[global_i] < yrange[0]) {
              yrange[0] = yvalBE.val[global_i];
            }

            if (yvalBE.val[global_i] > yrangeProduct[1]) {
              yrangeProduct[1] = yvalBE.val[global_i];
            }
            if (yvalBE.val[global_i] < yrangeProduct[0]) {
              yrangeProduct[0] = yvalBE.val[global_i];
            }
            global_i += 1;
          }

          console.log(interactionCode, colors[interactionCode], colors);

          {
            xseries.push({
              isStacked: true,
              targetAxisIndex: 1,
              areaOpacity: 0.0,
              color: colors[interactionCode],
              visibleInLegend: true,
            });
            y.push(yvalSpecs);

            if (this.showBestEffort) {
              yvalBE.val = array_difference(yvalBE.val, yvalSpecs.val);
              xseries.push({
                isStacked: true,
                targetAxisIndex: 1,
                lineWidth: 1,
                color: colors[interactionCode],
                pointsVisible: false,
                pointSize: 0,
                visibleInLegend: false,
              });
              y.push(yvalBE);
            }
          }
        }
      }

      xrange = [Math.min.apply(null, x), Math.max.apply(null, x)];

      i = 0;
      xticks = [];
      while (xticksList[i] < xrange[0]) {
        i += 1;
      }
      i -= 1;
      while (xticksList[i] < xrange[1]) {
        xticks.push(xticksList[i]);
        i += 1;
      }
      xticks.push(xticksList[i]);

      i = 0;
      yticks = [];
      yticksEnergy = [];
      while (yticksList[i] < yrange[0]) {
        i += 1;
      }
      i -= 1;

      while (yticksList[i] < yrange[1]) {
        if (yticksList[i] >= 1.0) {
          yticks.push({ v: yticksList[i], f: yticksList[i].toFixed(0) });
        } else {
          yticks.push({ v: yticksList[i], f: yticksList[i] });
        }

        if (yticksList[i] / this.repetitionRate >= 0.01) {
          if (
            Math.abs(yticksList[i] / this.repetitionRate - Math.round10(yticksList[i] / this.repetitionRate, 0)) < 0.001
          ) {
            fval = (yticksList[i] / this.repetitionRate).toFixed(0);
          } else {
            fval = (yticksList[i] / this.repetitionRate).toFixed(3);
          }

          yticksEnergy.push({ v: yticksList[i], f: fval });
        } else {
          yticksEnergy.push({
            v: yticksList[i],
            f: exponential_form(yticksList[i] / this.repetitionRate),
          });
        }
        i += 1;
      }
      yticks.push({ v: yticksList[i], f: yticksList[i].toFixed(0) });

      if (yticksList[i] / this.repetitionRate >= 0.01) {
        if (
          Math.abs(yticksList[i] / this.repetitionRate - Math.round10(yticksList[i] / this.repetitionRate, 0)) < 0.001
        ) {
          fval = (yticksList[i] / this.repetitionRate).toFixed(0);
        } else {
          fval = (yticksList[i] / this.repetitionRate).toFixed(3);
        }

        yticksEnergy.push({ v: yticksList[i], f: fval });
      } else {
        yticksEnergy.push({
          v: yticksList[i],
          f: exponential_form(yticksList[i] / this.repetitionRate),
        });
      }

      if (typeof xseries[0] !== "undefined") {
        xseries[0].targetAxisIndex = 0;
      } else {
        return;
      }
      var title = this.title;
      var width = $("#" + target_div).width();

      setTimeout(function () {
        drawTuningCurvesD3(
          x,
          y,
          target_div,
          { title: "Wavelength", units: "nm" },
          { title: "Power, mW" },
          {
            isStacked: true,
            title: title,
            legend: { position: "top", maxLines: 4, textStyle: { fontSize: 12 } },
            chartArea: {
              backgroundColor: { stroke: "#000", strokeWidth: 1 },
              // 'width' :'70%', 'top': '10%'
              left: 80,
              right: 80,
              bottom: 80,
              top: "10%",
            },
            width: width,
            height: width / 1.2,
            pointSize: 4,
            vAxes: {
              0: {
                title: "Power, mW",
                viewWindowMode: "explicit",
                viewWindow: { min: yticks[0].v, max: yticks[yticks.length - 1].v },
                // 'gridlines': {'color': 'transparent'},
                logScale: true,
                tickOptions: {
                  color: "#000",
                },
                titleTextStyle: {
                  italic: false,
                },
                ticks: yticks,
              },
              1: {
                title: "Energy, \u03BCJ",
                titleTextStyle: {
                  italic: false,
                },
                viewWindow: { min: yticks[0].v, max: yticks[yticks.length - 1].v },
                gridlines: { color: "transparent" },
                logScale: true,
                ticks: yticksEnergy,
              },
            },
            series: xseries,
            hAxis: {
              logScale: true,
              tickOptions: {
                color: "#000",
              },
              titleTextStyle: {
                italic: false,
              },
              viewWindow: { min: xticks[0], max: xticks[xticks.length - 1] },
              ticks: xticks,
              format: "#",
              // 'minorGridlines': {'count': 2, 'color': '#000'},
              slantedText: true,
              slantedTextAngle: 90,
            },
          }
        );
      }, 50);
      return;
    },
  },
};
</script>
<style lang="css" scoped>
label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

input {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}

label small {
  font-weight: normal;
  color: #999;
}
.tooltip2 .tooltiptext2 {
  top: -50px;
  left: 0px;
  width: 130px;
  right: 0px;
  font-size: 11px;
  visibility: visible;
}

.panel-image {
  width: 100%;
}
</style>
