export const c = 299792458;
export const pi = 3.14159265359;
import {RefractiveIndex} from "@/tools/MaterialDispersion.js"

export function fun_phase_template(zRayleigh) {
  return function (z) {
    return rad_to_deg(-Math.atan(z / zRayleigh));
  };
}
export function fun_curvature_template(zRayleigh) {
  return function (z) {
    return z * (1.0 + Math.pow(zRayleigh / z, 2));
  };
}
export function fun_diameter_template(w0, wl, M2) {
  return function (z) {
    return 2.0 * 1.0e3 * w0 * Math.sqrt(1 + Math.pow((M2 * wl * z) / pi / w0 / w0, 2.0));
  };
}
export function rad_to_deg(angle) {
  if (typeof angle !== "number") {
    angle = parseFloat(angle);
    // return;
  }
  return (angle * 180.0) / pi;
}

export function deg_to_rad(angle) {
  if (typeof angle !== "number") {
    angle = parseFloat(angle);
    //return;
  }
  return (angle / 180.0) * pi;
}

export function runDelayedFunction(callback, _time) {
  "use strict";
  var time;
  console.log(time);
  //delay not defined
  if (typeof _time === "undefined") {
    time = 500.0;
  } else {
    time = _time;
  }
  //zero delay
  if (_time === 0) {
    callback();
    return;
  }
  /*
    var i = loadingQueueAdd ();

    setTimeout(function() {
        callback();
        loadingQueueRemove(i);
    }, time);*/
}

//FRESNEL REFLECTION
export function fresnel_reflection(n, thetai) {
  var thetat = Math.asin(Math.sin(thetai) / n);

  var R_s = Math.pow(
    Math.abs((Math.cos(thetai) - n * Math.cos(thetat)) / (Math.cos(thetai) + n * Math.cos(thetat))),
    2.0
  );
  var R_p = Math.pow(
    Math.abs((Math.cos(thetat) - n * Math.cos(thetai)) / (Math.cos(thetat) + n * Math.cos(thetai))),
    2.0
  );

  return [R_s, R_p];
}

//DIFFRACTION ANGLES
//calculates diffraction angles for given wavelength [nm], AOI [rad], order m and grating frequency gpmm [lines per mm]
export function diffraction_angles(wl, theta0, m, gpmm) {
  //    if ( (m > (1.0 + Math.sin(theta0))/wl/gpmm*1.0e6) || (m < (Math.sin(theta0) - 1.0)/wl/gpmm*1.0e6) )
  //        return;

  var rhs = -Math.sin(theta0) - m * wl * gpmm * 1.0e-6;

  if (Math.abs(rhs) > 1.0) {
    return "";
  }

  return Math.asin(rhs);
}

//calculates AOI for which, specified deviance thetam1 of m=-1 diffraction beam is achieved
export function AOI_diffraction_deviance(thetad, wl, gpmm) {
  console.log(arguments);
  var l_a = 2.0 + 2.0 * Math.cos(thetad);
  var l_b = -2.0 * wl * gpmm * (1.0 + Math.cos(thetad));
  var l_c = -Math.pow(Math.sin(thetad), 2.0) + Math.pow(wl * gpmm, 2.0);

  var AOI1 = (-l_b + Math.sqrt(l_b * l_b - 4.0 * l_a * l_c)) / 2.0 / l_a;
  var AOI2 = (-l_b - Math.sqrt(l_b * l_b - 4.0 * l_a * l_c)) / 2.0 / l_a;

  if (thetad < 0.0) {
    return Math.asin(AOI1);
  }
  if (thetad >= 0.0) {
    return Math.asin(AOI2);
  }
}

//GROUP VELOCITY DISPERSION BY GRATING PAIR
//wl in [m], L in [m], theta0 in [rad], gpmm in [m-1]; result - in [fs2/mm]
export function grating_pair_GDD(wl, L, theta0, gpmm) {
  var GDD =
    ((-1.0e30 * Math.pow(wl, 3.0) * L * gpmm * gpmm) / (pi * c * c)) *
    Math.pow(1.0 - Math.pow(wl * gpmm - Math.sin(theta0), 2.0), -1.5);
  return GDD;
}

//THIRD-ORDER DISPERSION BY GRATING PAIR
//wl in [m], L in [m], theta0 in [rad], gpmm in [m-1]; result - in [fs3/mm]
export function grating_pair_TOD(wl, L, theta0, gpmm) {
  //return - 1.0e15  * (6.0 * pi * wl / c) * grating_pair_GDD(wl, L, theta0, gpmm) * (1.0 + wl * gpmm * Math.sin(theta0) - Math.pow(Math.sin(theta0), 2.0)) / (1.0 - (wl * gpmm - Math.sin(theta0), 2.0));
  //var TOD = - 1.0e15 * (3.0 * wl) / (2.0 * pi * c / gpmm) * grating_pair_GDD(wl, L, theta0, gpmm) * (1.0/gpmm - 2.0 * wl * wl * gpmm + 3.0 * wl * Math.sin(theta0) - Math.pow(Math.sin(theta0), 2.0)/ gpmm) / (1.0 - Math.pow(wl * gpmm - Math.sin(theta0), 2.0));
  var TOD =
    (((-1.0e15 * (3.0 * wl)) / (2.0 * pi * c)) *
      grating_pair_GDD(wl, L, theta0, gpmm) *
      (1.0 + wl * gpmm * Math.sin(theta0) - Math.pow(Math.sin(theta0), 2.0))) /
    (1.0 - Math.pow(wl * gpmm - Math.sin(theta0), 2.0));

  return TOD;
}

//returns phi of maximum d_eff, of selected material class
export function d_eff_phi(interaction_type, material) {
  if (typeof material.class === "undefined") {
    //        console.log ('Crystal class of', material.name, ' undefined')
    return;
  }

  var crystal_class = material.class;

  switch (crystal_class) {
    case "3m":
    case "-6m2":
      switch (interaction_type) {
        case "ooe":
          return 90.0;
        case "eoe":
        case "oee":
        case "eeo":
          return 0.0;
        case "oeo":
        case "eoo":
          return 90.0;
        default:
          return;
      }
    case "6":
    case "4":
    case "6mm":
    case "4mm":
    case "622":
    case "422":
      return "-";
    case "32":
    case "-42m":
      switch (interaction_type) {
        case "ooe":
          return 45.0;
        case "eoe":
        case "oee":
        case "eeo":
          return 0.0;
        case "oeo":
        case "eoo":
          return 45.0;
      }
      break;
    default:
      return "-";
  }
}

// d_eff EFFECTIVE NONLINEAR COEFFICIENT
export function d_eff (interaction_type, theta, phi, material) {

  if (typeof material.d === 'undefined') {
      //      console.log ('Susceptibility tensor of ', material.name, ' undefined')
      return;
  }


  if (typeof material.class === 'undefined') {
      //        console.log ('Crystal class of', material.name, ' undefined')
      return;
  }

  var d = material.d;
  var crystal_class = material.class;

  switch (crystal_class) {
      case '3m':
          switch (interaction_type) {
              case 'ooe': return d[2][0] * Math.sin(theta) - d[1][1] * Math.cos(theta) * Math.sin(3.0 * phi);
              case 'eoe':
              case 'oee': return d[1][1] * Math.pow(Math.cos(theta),2.0) * Math.cos(3.0 * phi);

              case 'eeo': return d[1][1] * Math.pow(Math.cos(theta),2.0) * Math.cos(3.0 * phi);
              case 'oeo':
              case 'eoo': return d[0][4] * Math.sin(theta) - d[1][1] * Math.cos(theta) * Math.sin(3.0 * phi);
              default: return;
          }
      case '6':
      case '4':
      switch (interaction_type) {
          case 'ooe': return d[2][0] * Math.sin(theta);
          case 'eoe':
          case 'oee': return d[0][3] * Math.sin(theta) * Math.cos(theta);

          case 'eeo': return - d[0][3] * Math.sin(2.0 * theta);
          case 'oeo':
          case 'eoo': return d[0][4] * Math.sin(theta);
          default: return;
      }
      case '6mm':
      case '4mm':
      switch (interaction_type) {
          case 'ooe': return d[2][0] * Math.sin(theta);
          case 'eoe':
          case 'oee': return 0.0;

          case 'eeo': return 0.0;
          case 'oeo':
          case 'eoo': return d[0][4] * Math.sin(theta);
          default: return;
      }
      case '32':
      switch (interaction_type) {
          case 'ooe': return d[0][0] * Math.cos(theta) * Math.cos (3.0 * phi);
          case 'eoe':
          case 'oee': return d[0][0] * Math.pow(Math.cos(theta), 2.0) * Math.sin (3.0 * phi) + d[0][3] * Math.sin(theta) * Math.sin(theta);

          case 'eeo': return d[0][0] * Math.pow(Math.cos(theta), 2.0) * Math.sin (3.0 * phi) - d[0][3] * Math.sin(2.0 * theta);
          case 'oeo':
          case 'eoo': return d[0][0] * Math.cos(theta) * Math.cos(3.0 * phi);
          default: return;
      }
      case '-4':
      switch (interaction_type) {
          case 'ooe': return - Math.sin(theta) * (d[2][0] * Math.cos(2.0 * phi) - d[2][5] * Math.sin(2.0 * phi));
          case 'eoe':
          case 'oee': return (d[0][3] + d[2][5]) * Math.sin(theta) * Math.cos(theta) * Math.cos(2.0*phi) - (d[2][4] + d[2][0])*Math.sin(theta) * Math.cos(theta) * Math.sin(2.0*phi);

          case 'eeo': return d[0][3] * Math.sin(2.0*theta) * Math.cos(2.0*phi) - d[0][4] * Math.sin(2.0*theta) * Math.sin(2.0*phi);
          case 'oeo':
          case 'eoo': return - Math.sin(theta) * (d[0][4] * Math.cos(2.0*phi) + d[0][3] * Math.sin(2.0*phi));
          default: return;
      }
      case '-42m':
      switch (interaction_type) {
          case 'ooe': return - d[2][5] * Math.sin(theta) * Math.sin(2.0*phi);
          case 'eoe':
          case 'oee': return (d[0][3] + d[2][5]) * Math.sin(theta) * Math.cos(theta) * Math.cos(2.0*phi);

          case 'eeo': return d[0][3] * Math.sin(2.0*theta) * Math.cos(2.0*phi);
          case 'oeo':
          case 'eoo': return - d[0][3] * Math.sin(theta) * Math.sin(2.0*phi);
          default: return;
      }
      case '622':
      case '422':
      switch (interaction_type) {
          case 'ooe': return 0.0;
          case 'eoe':
          case 'oee': return d[0][3] * Math.sin(theta) * Math.cos(theta);

          case 'eeo': return - d[0][3] * Math.sin(2.0*theta);
          case 'oeo':
          case 'eoo': return 0.0;
          default: return;
      }
      case '-6m2':
      switch (interaction_type) {
          case 'ooe': return - d[1][1] * Math.cos(theta) * Math.sin(3.0*phi);
          case 'eoe':
          case 'oee': return d[1][1] * Math.pow(Math.cos(theta), 2.0) * Math.cos(3.0*phi);

          case 'eeo': return d[1][1] * Math.pow(Math.cos(theta), 2.0) * Math.cos(3.0*phi);
          case 'oeo':
          case 'eoo': return - d[1][1] * Math.cos(theta) * Math.sin(3.0*phi);
          default: return;
      }
      case '-6':
      switch (interaction_type) {
          case 'ooe': return Math.cos(theta) * (d[0][0] * Math.cos(3.0*phi) - d[1][1] * Math.sin(3.0*phi));
          case 'eoe':
          case 'oee': return Math.pow(Math.cos(theta),2.0) * (d[0][0] * Math.sin(3.0*phi) + d[1][1] * Math.cos(3.0*phi));

          case 'eeo': return Math.pow(Math.cos(theta),2.0) * (d[0][0] * Math.sin(3.0*phi) + d[1][1] * Math.cos(3.0*phi));
          case 'oeo':
          case 'eoo': return Math.cos(theta) * (d[0][0] * Math.cos(3.0*phi) - d[1][1] * Math.sin(3.0*phi));
          default: return;
      }
      case '3':
      switch (interaction_type) {
          case 'ooe': return Math.cos(theta) * (d[0][0] * Math.cos(3.0*phi) - d[1][1] * Math.sin(3.0*phi)) + d[2][0] * Math.sin(theta);
          case 'eoe':
          case 'oee': return Math.pow(Math.cos(theta),2.0) * (d[0][0] * Math.sin(3.0*phi) + d[1][1] * Math.cos(3.0*phi)) + d[0][3] * Math.sin(theta) * Math.cos(theta);

          case 'eeo': return Math.pow(Math.cos(theta),2.0) * (d[0][0] * Math.sin(3.0*phi) + d[1][1] * Math.cos(3.0*phi)) - d[0][3] * Math.sin(2.0*theta);
          case 'oeo':
          case 'eoo': return Math.cos(theta) * (d[0][0] * Math.cos(3.0*phi) - d[1][1] * Math.sin(3.0*phi)) + d[0][4] * Math.sin(theta);
          default: return;
      }
  }
  return;
}


export function make_n_function (wl, ray, material) {
  switch(ray) {
      case 'o': return function() { return RefractiveIndex(wl, 0, material); };
      case 'e': return function(theta) { return RefractiveIndex(wl, 0, material)/Math.sqrt(1.0 + Math.pow(Math.sin(theta),2.0) * (Math.pow(RefractiveIndex(wl,0, material)/RefractiveIndex(wl,1, material),2.0) - 1.0)); };
  }
  return;
}

export function fsolve (fun, x0) {
  var dx = 1.0e-3;
  var x_new = x0;
  var x;
  var numiter=0;
  var maxiter=1000;
  var precision=1.0e-4;

  do {
      numiter++;
      x = x_new;
      x_new = x - dx / (fun(x+dx)/fun(x)-1.0) / 5.0; // daliklis pabaigoje tik tam, kad per greit nesokinetu
  } while ((Math.abs(x_new - x)>precision) && (numiter < maxiter));


  if ((numiter<maxiter-1)) {
      return x;
  }  else {
      return;
  }

}

//FIND MINIMUM OF FUNCTION:
// 1. Sample some points from region
// 2. Perform steepest descent from minimum
export function steepest_descent_w_sampling (fun, xmin, xmax) {
  var precision = 1.0e-6;
  var x;
  var x_new;
  var deltax_sampling = (xmax - xmin)/101.0;
  var deltax = 5.0e-6;
  var min=fun(xmin);
  var minarg = xmin;
  var fval;
  var step = (xmax - xmin)/1000.0;
  var i;
  var maxiter = 1000;

  if (typeof min !== 'number') {
      return;
  }

  for (x=xmin;x<xmax;x+=deltax_sampling) {
      fval = fun(x);

      if ((typeof fval === 'number') && (fval > min)) {
          min = fval;
          minarg = x;
      }
  }

  x = minarg; i = 0;
  var stop = false;

  while (stop) {
      i += 1;
      x_new = x - (fun(x + deltax) - fun(x))/deltax * step;

      if ( (i>maxiter) || (Math.abs(x_new-x)<precision) || (x_new>pi) || (x_new<-pi)) {
        stop = true;
      }
      x = x_new;
  }

  return [x, fun(x)];
}

export function test_inside (x, a, b) {
  if ((x >= a && x <= b) || (x >= b && x <= a)) {
    return true;
  } else {
    return false;
  }
}


export function make_phi_function (wl, theta0, thetac, material) {
  return function (phi) { return (Math.sin(theta0) - Math.sin(phi) * RefractiveIndex(wl, 0, material)/Math.sqrt(1.0 + Math.pow(Math.sin(thetac-phi),2.0) * (Math.pow(RefractiveIndex(wl,0,material)/RefractiveIndex(wl,1, material),2.0) - 1.0))); };
}